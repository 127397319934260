/* Import modern, minimal fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Space+Mono:wght@400;700&display=swap');

:root {
    /* Preserve your existing color system */
    --white: #fff;
    --black: #000;
    --error-red: #DB4141;
    --grid-grey: #e5e5e5;
    --grid-orange: #e58155;
    --grid-color: var(--grid-grey);
    --dark-grey: #3C3C3C;
    --off-white: #F2F2F2;
    --orange: #EC5909;
    --light-orange: #FF9A63;

    --sans: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
    --mono: 'Space Mono', monospace;

    --text-xs: 0.75rem;    /* 12px */
    --text-sm: 0.875rem;   /* 14px */
    --text-base: 1rem;     /* 16px */
    --text-lg: 1.125rem;   /* 18px */
    --text-xl: 1.25rem;    /* 20px */
    --text-2xl: 1.5rem;    /* 24px */
    --text-3xl: 2rem;      /* 32px */

    --leading-tight: 1.2;
    --leading-normal: 1.45; /* Preserved your original line height */
    --leading-relaxed: 1.75;
}

/* Base typography settings */
body {
    font-family: var(--sans), serif;
    font-size: var(--text-base);
    line-height: var(--leading-normal);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Monospace text - for when you need that technical feel */
.font-mono {
    font-family: var(--mono);
}

/* Preserve your layout settings */
html, body {
    height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--sans);
    margin-top: 0;
    font-weight: 600;
    line-height: var(--leading-tight);
}

h1.mono, h2.mono, h3.mono, h4.mono, h5.mono, h6.mono {
    font-family: var(--mono);
}

/*.MuiBreadcrumbs-root {*/
/*    background-color: var(--);*/
/*    padding: 0px;*/
/*    border-radius: 4px;*/
/*    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);*/
/*}*/